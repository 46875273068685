import { environment } from "src/environments/environment";

// const env = "dev"
const env = "qa"
// const env = "staging"
// const env = "production"

const getBaseUrl = (param) => {
    if (param == "production") {
        return `https://cvp.api.tatamotors`;
    }
    else {
        return `https://cvp-${param}.api.tatamotors`;
    }
}


const getS3BaseUrl = (param) => {
    if (param == "production") {
        return `https://cvp-prod-internal-service-portal.s3.ap-south-1.amazonaws.com/`;
    } else {
        return `https://cvp-${param}-internal-service-portal.s3.ap-south-1.amazonaws.com/`;
    }
}

const getSkinUrl = (param) => {
    if (param == "production") {
        return 'https://skinprod.api.tatamotors';
    } else {
        return 'https://skindevreplica.api.tatamotors';
    }
}

const BASE_URL = getBaseUrl(env);
export const S3_LINK = getS3BaseUrl(env);
export const SKIN_URL = getSkinUrl(env);


// const SAHAJ_BASE_URL = 'https://cvp-' + env + '.api.tatamotors/cv';

export const urlConstants = {
    GET_ACCESS_TOKEN: BASE_URL + '/api/isp-service-general/get-token',
    LOGIN_API: BASE_URL + '/api/user-onboarding/user-login',
    LOGIN_API_EDP: BASE_URL + '/api/edp/user-onboarding/user-login',
    GET_USER_DETAILS: BASE_URL + '/api/user-onboarding/get-user-details',
    ADD_USER: BASE_URL + '/api/user-onboarding/add-user',
    ADD_INTERNAL_ADMIN: BASE_URL + '/api/user-onboarding/add-tml-internal-admin-user',
    ADD_DEALER_ADMIN: BASE_URL + '/api/user-onboarding/add-dealer-admin-user',
    GET_ACTIVE_USERS: BASE_URL + '/api/user-onboarding/get-active-users-count',
    USER_DETAILS: BASE_URL + '/api/user-onboarding/user-list',
    CHANGE_USER_STATUS: BASE_URL + '/api/user-onboarding/change-status',

    LOGOUT_API: BASE_URL + '/api/user-onboarding/user-logout',
    FUEL_ODOMETER: BASE_URL + '/api/isp-vehicle-module/fuel-odometer-data',
    FUEL_ALERT: BASE_URL + '/api/isp-vehicle-module/get-fuel-alert-list',
    GET_EVENT_DATA: BASE_URL + '/api/isp-vehicle-module/event-data',
    IGNITION_DATA_API: BASE_URL + '/api/isp-vehicle-module/vehicle-state-data',
    // TELEMETRY_DATA_API: BASE_URL + '/api/isp-vehicle-module/vehicle-telementry-data', OLD API
    TELEMETRY_DATA_API: BASE_URL + '/api/isp-vehicle-module/vehicle-telementry-data-v1',
    // S3 apiendpoints
    S3_CAN_DATA_API : BASE_URL + '/api/isp-vehicle-module/get-can-data-from-s3-v1',
    S3_DTC_DATA_API : BASE_URL + '/api/isp-vehicle-module/get-dtc-data-from-s3-v1',
    S3_EVENT_DATA_API : BASE_URL + '/api/isp-vehicle-module/get-event-data-from-s3-v1',
    S3_TELEMETRY_DATA_API : BASE_URL + '/api/isp-vehicle-module/get-telemetry-data-from-s3-v1',
    S3_GENERIC_CAN_DATA_API: BASE_URL + '/api/isp-vehicle-module/get-generic-can-data-from-s3-for-cv-ev',
    // eol data
    EOL_DATA_API: BASE_URL + '/api/isp-vehicle-module/get-eol-isp-cv-search',
    // BS4 data
    BS4_CUSTODIAN_DATA_API : BASE_URL + '/api/isp-vehicle-module/get-vehicle-upload-details-bs4',

    VEHICLE_LIST_API: BASE_URL + '/api/isp-vehicle-module/get-vehicle-list-by-fleetowner-email',
    OFFBOARDING_HISTORY_API : BASE_URL + '/api/isp-vehicle-module/get-offboarding-history',
    VEHICLE_OFFBOARD_API : BASE_URL + '/api/isp-vehicle-module/get-vehicle-offboard',

    USER_ACTIVITY_DETAIL: BASE_URL + '/api/user-onboarding/get-event-logs',
    VEHICLE_HISTORICAL_CAN_DATA: BASE_URL + '/api/isp-vehicle-module/get-vehicle-historical-can-data',
    ADMIN_LOG: BASE_URL + '/api/isp-service-general/fetch-admin-log',
    ADMIN_REQUEST: BASE_URL + '/api/isp-vehicle-module/fetch-admin-login-request',
    UPDATE_ADMIN_LOGIN_REQUEST: BASE_URL + '/api/isp-vehicle-module/update-admin-login-request-status',
    ADMIN_ACCESS_STATUS: BASE_URL + '/api/isp-service/fleetedge-admin/get-admin-configuration',
    ADMIN_ACCESS_SET: BASE_URL + '/api/isp-service/fleetedge-admin/update-admin-configuration',
    REGISTER_SMS_TEMPLATE: BASE_URL + '/api/isp-vehicle-module/send-template-registration-email',
    ENABLE_VIN_FUEL_ALERT_V1: BASE_URL + '/api/isp-vehicle-module/enable-fuel-alert-v1',
    GET_VEHICLE_COMPLEX_EVENTS:BASE_URL + '/api/isp-vehicle-module/get-vehicle-complex-events',
    GET_ALL_FLEET_ID: BASE_URL + '/api/isp-vehicle-module/get-all-fleet-id',

    VEHICLE_STATUS: BASE_URL +'/api/isp-vehicle-module/get-fleet-id-by-vehicle-vin-v1',
    CHECK_BLOCKED_FLEET_ID_STATUS: BASE_URL + '/api/isp-service/get-user-block-status',
    UNBLOCK_FLEET_ID_STATUS: BASE_URL + '/api/isp-service/unblock-user',
    GET_VEHICLE_VINS_BY_CRM: BASE_URL + '/api/isp-vehicle-module/get-vins-by-crm',
    ADD_VEHICLE_MASTER: BASE_URL + "/api/isp-vehicle-module/add-vehicle-onboard",
    BULK_VEHICLE_MASTER: BASE_URL + "/api/isp-vehicle-module/vehicle-bulk-onboard",
    CHECK_VEHICLE_VALIDATION: BASE_URL + "/api/isp-vehicle-module/check-vehicle-validations",
    GET_USER_ID: BASE_URL + "/api/isp-vehicle-module/get-user-id",
    GET_BRANCH: BASE_URL + "/api/isp-vehicle-module/get-branch",
    GET_LOB_DROPDOWN: BASE_URL + "/api/isp-vehicle-module/get-vehicle-lob",
    GET_VEHICLE_FUEL_TREND : BASE_URL + "/api/isp-vehicle-module/get-telemetry-data-trends",
    
    //survey-all-API
    CREATE_SURVEY:BASE_URL +"/api/isp-service/fleetedge-admin/create-survey",
    GET_SURVEY_LIST_FOR_DUPLICATE:BASE_URL +"/api/isp-service/fleetedge-admin/get-survey-list",
    GET_QUESTION_FOR_SELECTED_SURVEY_ID:BASE_URL +"/api/isp-service/fleetedge-admin/get-survey-question-details",
    GET_SURVEY_DETAILS:BASE_URL +"/api/isp-service/fleetedge-admin/get-survey-details",
    GET_SURVEY_QUESTIONS:BASE_URL +"/api/isp-service/fleetedge-admin/get-survey-questions",
    GET_SURVEY_RESPONSE:BASE_URL +"/api/isp-service/fleetedge-admin/get-survey-responses",
    DOWNLOAD_SURVEY_RESPONSES:BASE_URL +"/api/isp-service/fleetedge-admin/download-survey-response",
    DELETE_SURVEY:BASE_URL +"/api/isp-service/fleetedge-admin/delete-survey",
    SUBSCRIPTION_TRANSACTION_HISTORY:BASE_URL +"/api/isp-vehicle-module/get-transaction-history",
    SUBSCRIPTION_INVOICE_RECEIPT:BASE_URL +"/api/isp-vehicle-module/get-receipts-for-fleet",
    SENSORIZE_STATUS:BASE_URL +"/isp/sensorize/",
    SUBSCRIPTION_TOTAL_TRANSACTION:BASE_URL+"/api/wrapper-service/get-count-of-transactions",
    BULK_FUNCTION: "bulk-function",

    reason: [
        {
          oldKey: "failure_reason",
          newKey: "reason",
        },
      ],

    EXPORT_DELETE_KEYS_COMMON: [
    "created_at",
    "created_by",
    "updated_at",
    "updated_by",
    "is_deleted",
  ],

    VEHICLE_MASTER_EXCEL_KEYS: [
        "profile_name",
        "branch_id",
        "vehicle_document",
        "corridor_id",
        "vehicle_due_data",
        "rejected_reason",
        "rc_book_document",
        "approval_status",
        "is_verified",
        "crn",
        "emition_nom",
        "first_sale_date",
        "arn",
        "speed_governer_renewed_date",
        "client_id",
        "pl",
        "lob",
        "veh_model",
        "vehicle_application",
        "tonnage",
        "vehicle_profile_link",
        "other_document",
        "vehicle_type",
      ],

    HTML_VARIABLES_BULK_UPLOAD: {
        bulkUpload: "- Bulk Upload",
        uploadLable: "Upload excel file of",
        list: "List",
        download: "Download Excel Template",
        downloadLinkLable: "Download the excel template",
        click: "Click here to see errors",
        errorToolTip: "Select branch from list",
        SucessToolTip: "Download bulk upload template from here",
        exportToolTip: "Download data to update",
        selectBranch: "Please Select branch",
        blanckSheetError: "Please provide appropriate data into the excel sheet",
        messageForUI:
          " After downloading, fill the template document with correct multiple information and upload it.",
        remove: "Remove",
        uploading: "Uploading",
        uploaded: "Uploaded",
      },


      VEHICLE_MASTER_ADDITIONAL_FIELD: [
        "truck_at_depo",
        "state_permit_due_date",
        "state_permit_renewed_on",
        "state_permit_rec_no",
        "national_permit_due_date",
        "national_permit_rec_no",
        "green_tax_due_date",
        "green_tax_rec_no",
        "tank_cert_per",
        "tank_cert_renewed_on",
        "tank_cert_vendor_inv",
        "tank_cert_vendor",
        "tank_recp_no",
        "tank_cert_no",
        "tax_next_due_date",
        "speed_co_name",
        "speed_gov_period",
        "speed_next_due_date",
        "speed_renewed_date",
        "speed_certificate_no",
        "speed_serial_no",
        "speed_seal_no",
        "compressor_reg_payment",
        "truck_at_discount_price",
        "body_roi",
        "body_date_from",
        "body_agreement_no",
        "body_finance_co",
        "truck_invoice_no",
        "insurance_policy_no",
        "fitness_no",
        "make_year_month",
        "additional_fields",
        "truck_finance_date_from",
      ],

      DELETE_KEYS_ADDITIONAL: [
        "truck_chassis_number",
        "truck_load_capacity",
        "truck_type",
        "maker_year_month",
        "fitness_number",
        "fitness_due_date",
        "fitness_renewed_on",
        "fitness_amount",
        "fitness_period",
        "insurance_co",
        "insurance_policy_number",
        "insurance_renew_date",
        "insurance_due_date",
        "truck_dealer_name",
        "truck_invoice_date",
        "truck_invoice_number",
        "truck_at_discount_price",
        "truck_cgst",
        "truck_sgst",
        "truck_igst",
        "truck_tcs",
        "truck_registration_changes",
        "truck_finance_co",
        "truck_finance_agreement_no",
        "truck_finance_date_to",
        "truck_finance_date_from",
        "truck_finance_aggreement_no",
    
        "body_finance_date_from",
        "body_finance_date_to",
        "truck_at_discount_price_1",
        "road_tax_next_due_date",
        "tank_certificate_vendor_invoice",
        "tank_certificate_renewed_on",
        "green_tax_receipt_no",
        "national_permit_receipt_no",
        "state_permit_receipt_no",
        "state_permit_renewed_date",
    
        "truck_finance_installment_date",
        "truck_finance_installment_amt",
        "truck_finance_type",
        "body_type",
        "body_master_name",
        "body_invoice_value",
        "body_invoice_date",
        "body_discount_price",
        "body_cgst",
        "body_sgst",
        "body_igst",
        "body_tcs",
        "body_registration_changes",
        "body_finance_type",
        "body_finanace_agreement_number",
        "body_finance_type",
        "finance_type",
        "body_finanace_agreement_number",
        "body_finanace_date_to",
        "body_invoice_date",
        "body_finanace_date_from",
        "body_installment_amt",
        "body_rate_of_interest",
        "compressor",
        "compressor_invoice_value",
        "compressor_invoice_date",
        "compressor_invoice_no",
        "truck_at_discount_price",
        "compressor_cgst",
        "compressor_sgst",
        "compressor_igst",
        "compressor_tcs",
        "compressor_registration_payment",
        "speed_governer",
        "speed_governer_seal_no",
        "speed_governer_serial_no",
        "speed_governer_certificate_no",
        "speed_governer_renewed_date_no",
        "speed_governer_next_due_date",
        "speed_governer_period",
        "speed_governer_co_name",
        "road_tax",
        "road_tax_receipt_no",
        "road_tax_amt",
        "road_tax_period",
        "road_tax_renewed_on",
        "tax_next_due_date",
        "tank_certificate_no",
        "tank_certificate_amount",
        "tank_certificate_vendor",
        "tank_certificate_invoice",
        "tank_certificate",
        "tank_next_due_date",
        "tank_certificate_period",
        "green_tax",
        "green_tax_reciept_no",
        "green_tax_amount",
        "green_tax_renewed_on",
        "green_tax_next_due_date",
        "green_tax_period",
        "national_permit",
        "national_permit_reciept_no",
        "national_permit_amt",
        "national_permit_renewed_date",
        "national_permit_next_due_date",
        "national_permit_period",
        "state_permit",
        "state_permit_reciept_no",
        "state_permit_amt",
        "state_permit_renewed_on",
        "state_permit_next_due_date",
        "state_permit_period",
        "truck_status",
        "truck_running_at_depo",
        "from_date",
        "to_date",
        "own_out_side",
        "truck_sale",
        "truck_scrap",
      ],

      s3Link: 'https://cvp-dev-bulk-upload-templates.s3.ap-south-1.amazonaws.com/redesign-mvp0/',
      s3Mvp1URL: 'https://cvp-dev-bulk-upload-templates.s3.ap-south-1.amazonaws.com/redesign-mvp1/',
      s3CvpURL: 'https://cvp-dev-bulk-upload-templates.s3.ap-south-1.amazonaws.com',

      SELECT_DROPDOWN_MODULE: [
        { id: 1, name: "Add" },
        { id: 2, name: "Update" },
      ],

    // API_LAST_STATE_VEHICLE_STREAM: BASE_URL + '' + "/api/vehicle-service/vehicle-state/vin",    
    API_LAST_STATE_VEHICLE_STREAM: BASE_URL + '/api/isp-vehicle-module/get-vehicle-state',
    API_GET_PLACES_ON_MAP: BASE_URL + "/api/isp-vehicle-module/get-place",
    API_SINGLE_VEHICLE_CAN: BASE_URL + '/api/isp-vehicle-module/get-can-data-for-dic',
    API_GET_CAN_DATA_FOR_DIC : BASE_URL + '/api/isp-vehicle-module/get-can-data-for-dic',
    API_VEHICLE_SERVICE_GET_VEHICLE_VIN_NOS: BASE_URL + '/api/vehicle-service/get-vin-for-dashboard',
    API_GET_DEF_QUALITY_DATA_DIC: BASE_URL + '/api/isp-vehicle-module/get-def-quality-for-dic-v1',
    API_GET_DIC_TIME_TO_SERVICE: BASE_URL + '/api/isp-vehicle-module/get-dic-time-to-service',
    API_POI_MASTER_DATA: BASE_URL + '/api/isp-vehicle-module/get-poi-data',

      // POI data
    API_POI_DATA: BASE_URL + '/api/wrapper-service/get-poi-data',
    API_TRIP_SERVICE_GET_LOCATION_LIST: BASE_URL + '/api/trip-service/location/get-locations',
    API_GET_NEAREST_CHARGING_STATIONS: BASE_URL + '/api/vehicle-service/get-nearest-charging-station',
    // API_SINGLE_VEHICLE_STREAM: BASE_URL + "/api/isp-vehicle-module/get-vehicle-state",
    API_PUSH_NOTIFICATION: BASE_URL + '/api/isp-service/fleetedge-admin/send-notification',
    FOR_SENSORIZE_GET_ACCESS_TOKEN: BASE_URL + '/auth/realms/service_account/protocol/openid-connect/token',
};

export const redirectUrlConstants = {
    REDIRECT_EVENT_DATA: '/eventdata',
    REDIRECT_LOGIN: '/login',
    REDIRECT_USER_ONBOARD: '/onboardUser'
}
