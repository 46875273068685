import { Component, OnInit,ViewChild } from '@angular/core';
import { IMyDpOptions, IMyDateModel, IMyDate } from 'mydatepicker';

import { BaseApiService } from 'src/app/base-api.service';
import { NgxSpinnerService } from 'ngx-spinner';

import * as XLSX from 'xlsx';
import { RouterModule, Router } from '@angular/router';
 
 import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { HttpClient, HttpHeaders } from '@angular/common/http';

declare var myExtObject: any;
declare var webGlObject: any;
@Component({
  selector: 'app-client-api',
  templateUrl: './client-api.component.html',
  styleUrls: ['./client-api.component.scss']
})
export class ClientApiComponent implements OnInit {
	
	
       

  constructor(private router: Router,private baseAPI: BaseApiService, public spinnerService: NgxSpinnerService,private http: HttpClient) {
          var token =localStorage.getItem('token');
	       
			if(token==undefined || token==='' ){
			  this.router.navigate(['/login']);
			}
			
			 webGlObject.init();
			

		 }
	  
	   
		dropdownList = [];
		selectedItems = [];			
		dropdownSettings: IDropdownSettings;
		resultCheck:boolean = false; 	
		globalData:any=[];
		editStartDate:string;
		editEndDate:string;
		start_date:string;
		end_date:string;
		clientMobile:string;
		clientEmail:string;
		clientName:string;
		public  apps: any[] = [];
		vechileDetails: any;
		public min = new Date(2018, 1, 12, 10, 30);

		public yearMax=this.getCurrentDay('Y')+1;
		public monthMax=this.getCurrentDay('M');
		public dateMax=this.getCurrentDay('D');
		private todate = new Date();
		public Hours=this.todate.getHours();
		public getMinutes=this.todate.getMinutes();
		public max = new Date(this.yearMax, this.monthMax, this.dateMax, 20, 30);

	
	 public myDate: IMyDate = {

    year: this.getCurrentDay('Y'),
    month: this.getCurrentDay('M'),
    day: this.getCurrentDay('D'),
  }
	 
  getCurrentDay(cat): any {
    let output;
    var today = new Date();
    var day = today.getDate();
	 var month = today.getMonth() + 1; //January is 0!
    if (day != 1) {
        //day = day - 1;
    }else{
		if(month==5 || month==7 || month==10 || month==12)
		{
			day=30;
		}
		
		if(month==2 || month===4 || month==6 || month==8 ||month==9 || month==11 || month==1)
		{
			day=31;
		}
		
		if (year % 4 == 0 && month==3) 
		{
			day=29;
		}
		else if (year % 4 != 0 && month==3) 
		{
			day=28;
		}
		month=month-1;
	}

   
	//alert(day+"#"+month);
    var year = today.getFullYear();

    if (cat === 'D') {
      output = day;
    }
    else if (cat === 'M') {
      output = month;
    }
    else {
      output = year;
    }
    return output;
  }
  public myDatePickerOptions: IMyDpOptions = {
    dateFormat: 'yyyy-mm-dd',
    markCurrentDay: true,   
    disableSince :  this.myDate

  };
  
  
  
    compareDate()
	{
		
		
		var startDate = new Date(this.editStartDate);
		var endDate = new Date(this.editEndDate);

		var seconds = (endDate.getTime() - startDate.getTime()) / 1000;

	   if(this.editEndDate!=null && this.editStartDate!=null)
		{
			
			
			 this.editStartDate=this.convert(this.editStartDate);
	         this.editEndDate=this.convert(this.editEndDate);
			 
			 
		}
	}
  
  convert(str) {
	 
  var date = new Date(str),
    mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    day = ("0" + date.getDate()).slice(-2);
    var Hours=date.getHours();
    var getMinutes=date.getMinutes();
	
	var returnDtae=[date.getFullYear(), mnth, day].join("-");
	returnDtae=returnDtae+"T"+Hours+":"+getMinutes+":00";
  return returnDtae;
}

  onEditStartDateChanged(event) {
	 

   var splitedDate=this.start_date.toString().split(",");
	 
	  this.editStartDate=splitedDate[0];
	  this.editEndDate=splitedDate[1];
	  

	 
		if(this.editEndDate!=null && this.editStartDate!=null)
		{
			this.compareDate();
			
			
		}
		
		
	 }
	 
	applyFilter()
	{
		
		
		
	}
	 fetchReport() {
	  
	 
	 
	
	if(this.editStartDate === undefined && this.editEndDate !== undefined )
	{
		alert('Please enter proper date in the Start date.');
		this.editStartDate=null;
		this.editEndDate=null;
		return false;
	}
	if(this.editStartDate !== undefined && this.editEndDate === undefined )
	{
		alert('Please enter  proper date in the End date.');
		this.editStartDate=null;
		this.editEndDate=null;
		return false;
	}
	if(this.editStartDate === undefined && this.editEndDate === undefined )
	{
		alert('Please enter  proper date in the Start date and End date.');
		this.editStartDate=null;
		this.editEndDate=null;
		return false;
	}

    if ((this.editStartDate === undefined || this.editStartDate === '' || this.editStartDate === null) ||
      (this.editEndDate === undefined || this.editEndDate === '' || this.editEndDate === null) ||
      (this.clientName === undefined || this.clientName === '' || this.clientName == '' || this.clientName == null ) ||(this.clientEmail === undefined || this.clientEmail === '' || this.clientEmail == '' || this.clientEmail == null) ) {
        alert('Please fill all mandatory fields.');

    }
    else {  

     
     

		if (window.confirm("Do you want to submit data?")) 
	   {
		   
		    this.callApi();
           
        } 
		else 
		{
            alert("Report serach is failed");
        }

    }
  }
	//18602676060
	
	//==================================================================
	
	callApi() {
    this.spinnerService.show();
	
	

	
   var data = {'clientName':this.clientName, 'validityStartDate' : this.editStartDate,'validityEndDate' : this.editEndDate,'emailId' : this.clientEmail,'mobile' : this.clientMobile};	
	

	var datastring="admin/external/signup";
	// myExtObject.firebaseEvent('Client Sign Up', { user_name: localStorage.getItem('username'),fromDate:this.editStartDate,toDate:this.editEndDate,button_name:"Submit Button Click"});
	
	this.baseAPI.callOnboardPostApi(this.baseAPI.onboardVinUrl+datastring,data).then((data:any) => {
       
		if(data.clientId!=undefined)
		{
             alert('Registration has been done');

			this.router.navigate(['/client']);
			this.spinnerService.hide();
		}else{
			alert(data);
			this.spinnerService.hide();


		}
      
    }, (err) => {

      this.spinnerService.hide();
      var jsonObj = JSON.parse(JSON.stringify(err));
	  //alert(JSON.stringify(jsonObj));
      var message = jsonObj.error;
      if (message != undefined) {
        alert(message);
      }
      else {
        alert('Please try again');
      }
    });
	
	

  }
  
 


  ngOnInit() {
	    this.monthMax=this.monthMax-1;
	   this.max = new Date(this.yearMax, this.monthMax, this.dateMax, this.Hours, this.getMinutes);
	  
  }
  
  
  
  
  
  
 
        }

       
 

