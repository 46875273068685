// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lyt-main {
  padding: 15px;
}

@media only screen and (max-width: 1200px) {
  :host ::ng-deep .cp-section {
    padding: 65px;
  }
}
@media screen and (max-width: 1100px) and (min-width: 320px) {
  :host ::ng-deep .form-group {
    padding-right: 75px;
  }
  :host ::ng-deep .btn.btn-default {
    position: unset;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/Components/vehicle-master-crn/vehicle-master-crn.component.scss","webpack://./../../IPS%20frontend/cvp-infinity-internal-service-portal-frontend/src/app/Components/vehicle-master-crn/vehicle-master-crn.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;ACCF;;ADEA;EACI;IACE,aAAA;ECCJ;AACF;ADEE;EAEK;IACD,mBAAA;ECDJ;EDGE;IACE,eAAA;ECDJ;AACF","sourcesContent":[".lyt-main{\r\n  padding: 15px;\r\n}\r\n\r\n@media only screen and (max-width: 1200px) {\r\n    :host ::ng-deep .cp-section {\r\n      padding: 65px;\r\n    }\r\n  }\r\n\r\n  @media screen and (max-width: 1100px) and (min-width: 320px){\r\n    :host ::ng-deep{\r\n       .form-group {\r\n      padding-right:75px ;\r\n    }\r\n    .btn.btn-default{\r\n      position: unset;\r\n    }\r\n  }\r\n}",".lyt-main {\n  padding: 15px;\n}\n\n@media only screen and (max-width: 1200px) {\n  :host ::ng-deep .cp-section {\n    padding: 65px;\n  }\n}\n@media screen and (max-width: 1100px) and (min-width: 320px) {\n  :host ::ng-deep .form-group {\n    padding-right: 75px;\n  }\n  :host ::ng-deep .btn.btn-default {\n    position: unset;\n  }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
