import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { VehicleCanDataService } from 'src/app/services/vehicle-can-data.service';
import { errorMessages } from 'src/app/shared/configurations/error-messages-constants';
import { checkPattern } from 'src/app/shared/configurations/pattern-constants';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-vehicle-master-crn',
  templateUrl: './vehicle-master-crn.component.html',
  styleUrls: ['./vehicle-master-crn.component.scss']
})
export class VehicleMasterCrnComponent implements OnInit {
  panLabel: string = "PAN ";
  mobileLable: string = "Mobile Number";
  checkpattern = checkPattern;
  errorMessages = errorMessages;
  data_count: number = 10;
  vehicleCrmForm: UntypedFormGroup;
  showTableData: boolean = false;
  vinListarray: [];
  totalRecords: number = 0;
  submitted=false;
  userInfo: any = JSON.parse(localStorage.getItem('loggedInUserInfo'));

  reqObj: any = {
    "user_id": this.userInfo.user_id,
    "auser_name": this.userInfo.user_id,
    "device_id": "bf1f6b4d-65cc-89e0-3aca-b9cfe7e09498",
    "app_name": "com.tatamotors.infinity",
    "phone_number": "",
    "pan_number": "",
    "is_portal": true,
    "page_number": 1,
    "data_count": this.data_count
  };


  columns: Column[] = [{ dataKey: "sr_no", displayName: "SR. NO." }, { dataKey: "vins", displayName: "VIN" }];
  constructor(private vehicleApi: VehicleCanDataService,
    private formBuilder: UntypedFormBuilder,
    private toaster: ToastrService) { }

  ngOnInit() {
    this.vehicleCrmForm = this.formBuilder.group({
      mobileNumber: ['', [Validators.required, Validators.pattern(this.checkpattern.MOBILE)]],
      panCardNumber: ['', [Validators.required, Validators.pattern(this.checkpattern.PAN_NO)]],
    });
  }

  submitData() {
    this.submitted=true;
    if (this.vehicleCrmForm.invalid) {
			return;
		}
    this.reqObj.phone_number = this.vehicleCrmForm.value.mobileNumber;
    this.reqObj.pan_number = this.vehicleCrmForm.value.panCardNumber;
    this.vehicleApi.getVehichleVinsByCrm(this.reqObj).subscribe(
      (data: any) => {
        if (data.status === 0 && data.hasOwnProperty("results") === true) {
          if (data.results.vins.length <= 0) {
            this.showTableData = false;
            this.toaster.error("No record found", '', {
              timeOut: 5000
            });
          } else {
            let page = this.reqObj.page_number;
            let previousePage = this.reqObj.page_number - 1;
            let tempsrno = page === 1 ? 1 : previousePage * this.data_count + 1;
            this.vinListarray = data.results.vins.map((e, i) => {
              return { 'sr_no': tempsrno + i, 'vins': e }
            });
            this.totalRecords = data.results.total_count;
            this.showTableData = true;
            this.toaster.success(data.message, '', {
              timeOut: 5000
            });
          }
        } else {
          this.showTableData = false;
          this.toaster.error(data.message, '', {
            timeOut: 5000
          });
          this.vehicleCrmForm.reset();
        }
      }, (error) => {
        this.toaster.error(error.message, '', {
          timeOut: 5000
        });
      });
  }

  nextPage(page) {
    this.reqObj.page_number = page;
    this.submitData();
  }

  get getformControls() {
    return this.vehicleCrmForm.controls;
  }

}
