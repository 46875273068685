import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { appLevelConstants } from '../../shared/configurations/app-level-constants';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { checkPattern } from 'src/app/shared/configurations/pattern-constants';
import { errorMessages } from 'src/app/shared/configurations/error-messages-constants';
import { LoginService } from 'src/app/login.service';
import { ToastrService } from 'ngx-toastr';
import { ConfigService } from 'src/app/config.service';
import { debounceTime } from 'rxjs/operators';
import { FirebaseService } from 'src/app/services/firebase.service';
import { TM_LOGO } from '@shared/configurations/image-constants';
declare var myExtObject: any;
declare var webGlObject: any;
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit {
  TM_LOGO = TM_LOGO;
  checkpattern = checkPattern;
  invalidCredentialsView: boolean;
  checked: boolean = false;
  reqObj: any;
  res: void;
  access_token: string;
  authenticationToken: any;
  public showLoading = false;
  public loginStep: boolean = true;
  public showPwd: boolean = false;
  public selectedRole: string;

  // labels 
  loginTitle = appLevelConstants.LOGIN;
  emailId = appLevelConstants.EMAIL;
  desc = appLevelConstants.DESC;
  passwordPlaceHodler = appLevelConstants.PASSWORD;
  termConditions = appLevelConstants.TERMCONDITION;
  roles = appLevelConstants.ROLE;
  selectRole = appLevelConstants.ROLE_CHOICE;
  loginButton = appLevelConstants.LOGIN_BUTTON;
  passwordRequired = appLevelConstants.PASSWORD_REQUIRED;
  emailRequired = appLevelConstants.EMAIL_REQUIRED;
  emailInvalid = appLevelConstants.EMAIL_INVALID;
  loginForm: UntypedFormGroup;
  loginSubmitted = false;
  errormessages = errorMessages;
  selected: any;
  tokenResponseStatus: number;
  email: any;
  userNameValidator: any;
  emailIdValidator: any;
  supportRole: boolean;
  keyCloakTokenRequestObject: any = {};
  userName: void;

  constructor(
    public spinnerService: NgxSpinnerService,
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    public loginService: LoginService,
    private toaster: ToastrService,
    private configService: ConfigService,
    private firebaseService: FirebaseService,

  ) {  }

  ngOnInit() {
    this.createForm();
    this.loginForm.controls.roles.valueChanges.pipe(debounceTime(500)).subscribe((searchValue) => {
      this.userNameValidator = this.loginForm.get('userName');
      this.emailIdValidator = this.loginForm.get('emailId');
      if (searchValue && searchValue.userRole === 'Support Desk') {
        this.supportRole = true;
        this.userNameValidator.setValidators([Validators.required,
        Validators.minLength(4),
        Validators.maxLength(50)]);
        this.emailIdValidator.clearValidators();
        this.emailIdValidator.setErrors(null);
      } else {
        this.supportRole = false;
        this.emailIdValidator.setValidators([Validators.required,
        Validators.required,
        Validators.pattern(this.checkpattern.EMAIL_ID)]);
        this.userNameValidator.clearValidators();
        this.userNameValidator.setErrors(null);
      }
    });
  }
  
  createForm() {
    this.loginForm = this.formBuilder.group(
      {
        userName: ['', [
          Validators.required,
          Validators.minLength(4),
        ]],
        password: ['', [
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(20),
        ]],
        roles: ['', [
          Validators.required,
        ]],
        emailId: ['', [Validators.required,
        Validators.pattern(this.checkpattern.EMAIL_ID)]],
      }
    );
  }

  // FUNCTION: For Form Controls(convenience getter for easy access to form fields)
  // WRITTEN BY: YATIN RAI
  // WRITTEN ON: 16-03-2021
  // MODIFIED BY:
  get formControls() {
    return this.loginForm.controls;
  }

  // FUNCTION: For Form Values(convenience getter for accessing to form values)
  // WRITTEN BY: YATIN RAI
  // WRITTEN ON: 16-03-2021
  // MODIFIED BY:
  get formValue() {
    return this.loginForm.value;
  }

  // FUNCTION: For getting Keycloak token and User Login
  // WRITTEN BY: YATIN RAI
  // WRITTEN ON: 16-03-2021
  // MODIFIED BY:
  login() {
    this.loginSubmitted = true;
    this.keyCloakTokenRequestObject = {
      user_id: this.formValue.roles.userRole === 'Support Desk' ? this.formValue.userName : this.formValue.emailId.toLowerCase(),
      role_type: this.formValue.roles.userRole,
      password: this.formValue.password,
      action: appLevelConstants.LOGIN_ACTION_GET_TOKEN,
      module_name: appLevelConstants.LOGIN_MODULE_NAME
    };
    
    // checking form state (valid/invalid)
    if (this.loginForm.invalid || !this.selected) {
      if (!this.selected) {
        this.checked = true;
      }
      else {
        this.checked = false;
      }
      return;
    } else {
      this.showLoading = true;
      //API call to Get Keycloak Token
      this.loginService.fetchKeyCloakToken(this.keyCloakTokenRequestObject).subscribe(
        (response: any) => {
          this.showLoading = false;
          if (response.status === 0 && response.result && response.result[0].access_token) {
            localStorage.setItem('role_type', this.formValue.roles.userRole);
            this.userName = localStorage.setItem('user_id', this.formValue.roles.userRole === 'Support Desk' ?
              this.formValue.userName : this.formValue.emailId);
            localStorage.setItem('auth_token', response.result[0].access_token);
            localStorage.setItem('refresh_token', response.result[0].refresh_token);
            this.tokenResponseStatus = response.status;
          }
          else {
            this.toaster.error(response.message, '', {
              timeOut: 5000
            });
          }
          this.userLogin(this.tokenResponseStatus);
        },
        (error) => {
          this.showLoading = false;
          this.toaster.error(error.error.error_description, '', {
            timeOut: 5000
          });
        }
      );
    }
  }

  // FUNCTION: For User Login
  // WRITTEN BY: YATIN RAI
  // WRITTEN ON: 27-03-2021
  // MODIFIED BY:
  userLogin(responseStatus) {
    if (responseStatus === 0) {
      this.reqObj = {
        user_id:  localStorage.getItem('user_id'),
        auser_name:  localStorage.getItem('user_id'),
        role_type: this.formValue.roles.userRole,
        action: appLevelConstants.LOGIN_ACTION_GET_TOKEN,
        module_name: appLevelConstants.LOGIN_MODULE_NAME
      };
      //API call for User Login
      this.loginService.userLogin(this.reqObj).subscribe(
        (response: any) => {
          this.firebaseService.createEvent('User Login', { 
            user_name: localStorage.getItem('user_id'),
            user_role:  localStorage.getItem('role_type')
          });
          if (response) {
            if (response.hasOwnProperty('result')) {
              if (response.result && response.result.length > 0) {
                this.configService.setLoggedInUserInfo(response.result[0]);
                this.router.navigateByUrl('/userProfile');
              }
            }
            else {
              this.toaster.error(response.message, '', {
                timeOut: 5000
              });
            }
          }
          else {
            this.toaster.error(response.message, '', {
              timeOut: 5000
            });
          }
        },
        (error) => {
          this.toaster.error(error.message, '', {
            timeOut: 5000
          });
          this.router.navigateByUrl('/login');
        }
      );
    }
  }

  // FUNCTION: For changing the value of checkbox
  // WRITTEN BY: YATIN RAI
  // WRITTEN ON: 16-03-2021
  // MODIFIED BY:
  changeSelection() {
    this.selected = !this.selected;
    if (this.selected) {
      this.checked = false;
    }
    else {
      this.checked = true;
    }
  }
}
