import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CalendarModule } from 'primeng/calendar';
import { CalendarComponent } from '../shared/calendar/calendar.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatToolbarModule } from '@angular/material/toolbar';
// import { GaugesModule } from 'ng-canvas-gauges';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgxLoadingModule, ngxLoadingAnimationTypes } from 'ngx-loading';
import { AgmOverlays } from "agm-overlays";
// import { AgmDirectionModule } from "agm-direction";
import { AccordionModule } from 'primeng/accordion';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CheckboxModule } from 'primeng/checkbox';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { Select } from 'primeng/select';
import { FileUploadModule } from 'primeng/fileupload';
import { InputSwitchModule } from 'primeng/inputswitch';
import { MultiSelectModule } from 'primeng/multiselect';
import { PaginatorModule } from 'primeng/paginator';
import { RadioButtonModule } from 'primeng/radiobutton';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SliderModule } from 'primeng/slider';
import { TableModule } from 'primeng/table';
import { AgmCoreModule } from '@agm/core';
import { LayoutModule } from '@angular/cdk/layout';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { ToastrModule } from 'ngx-toastr';
import { ToastModule } from 'primeng/toast';
import { environment } from 'src/environments/environment';
import { PaginationComponent } from './pagination/pagination.component';
import { TablePaginationComponent } from './table-pagination/table-pagination.component';
import { QueryComponent } from './query/query.component';
import { QueryResultComponent } from './query-result/query-result.component';
import { InputGroupDirective } from './query/input-group.directive';
import { MatTabsModule } from '@angular/material/tabs';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { TabViewModule } from 'primeng/tabview';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatOptionModule } from '@angular/material/core';

@NgModule({
  declarations: [
    CalendarComponent,
    PaginationComponent,
    TablePaginationComponent,
    QueryComponent,
    QueryResultComponent,
    InputGroupDirective
  ],
  imports: [
    MatOptionModule,
    MatFormFieldModule,
    MatSelectModule,
    CommonModule,
    CalendarModule,
    FormsModule,
    ReactiveFormsModule,
    AutoCompleteModule,
    // GaugesModule,
    DropdownModule,
    Select,
    FileUploadModule,
    AccordionModule,
    CheckboxModule,
    DialogModule,
    SelectButtonModule,
    InputSwitchModule,
    RadioButtonModule,
    SliderModule,
    MatSlideToggleModule,
    MultiSelectModule,
    TableModule,
    PaginatorModule,
    CheckboxModule,
    NgxSpinnerModule,
    NgxLoadingModule,
    ConfirmDialogModule,
    ToastModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      maxOpened: 1,
      autoDismiss: true,
      preventDuplicates: true,
      positionClass: 'toast-bottom-right',
    }),
    NgxLoadingModule.forRoot({
      animationType: ngxLoadingAnimationTypes.threeBounce,
      backdropBackgroundColour: 'rgba(0,0,0,0.1)',
      backdropBorderRadius: '4px',
      primaryColour: '#0a0a5f',
      secondaryColour: '#0a0a5f',
      tertiaryColour: '#0a0a5f',
      fullScreenBackdrop: true
    }),
    NgMultiSelectDropDownModule.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: environment.GOOGLE_MAPS_API_KEY,
      libraries: ['places', 'drawing', 'geometry']
    }),
    AgmOverlays,
    // AgmDirectionModule,
    LayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    NgxDaterangepickerMd.forRoot(),
    MatTabsModule,
    MatProgressBarModule,
    NgxSliderModule,
    TabViewModule,
    MatSelectModule,
    MatFormFieldModule
  ],
  exports: [
    MatOptionModule,
    MatFormFieldModule,
    MatSelectModule,
    CalendarModule,
    FormsModule,
    ReactiveFormsModule,
    AutoCompleteModule,
    // GaugesModule,
    DropdownModule,
    Select,
    FileUploadModule,
    AccordionModule,
    CheckboxModule,
    DialogModule,
    SelectButtonModule,
    InputSwitchModule,
    RadioButtonModule,
    SliderModule,
    MatSlideToggleModule,
    MultiSelectModule,
    TableModule,
    PaginatorModule,
    CheckboxModule,
    NgxSpinnerModule,
    NgxLoadingModule,
    ConfirmDialogModule,
    ToastModule,
    NgMultiSelectDropDownModule,
    AgmCoreModule,
    AgmOverlays,
    // AgmDirectionModule,
    LayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    NgxDaterangepickerMd,
    CalendarComponent,
    PaginationComponent,
    TablePaginationComponent,
    QueryComponent,
    QueryResultComponent,
    InputGroupDirective,
    MatTabsModule,
    MatProgressBarModule,
    NgxSliderModule,
    TabViewModule,
    MatSelectModule,
    MatFormFieldModule
  ]
})
export class SharedModule { }
