import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { checkPattern } from 'src/app/shared/configurations/pattern-constants';
import { errorMessages } from 'src/app/shared/configurations/error-messages-constants';
import { BlockedIdManagementService } from 'src/app/services/blocked-id-management.service'
import { ToastrService } from 'ngx-toastr';
import { appLevelConstants } from 'src/app/shared/configurations/app-level-constants'



declare var webGlObject: any;

@Component({
  selector: 'app-blocked-id-management',
  templateUrl: './blocked-id-management.component.html',
  styleUrls: ['./blocked-id-management.component.scss']
})
export class BlockedIdManagementComponent implements OnInit {

  constructor(
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private blockedIdManagementService: BlockedIdManagementService,
    public toaster: ToastrService,
  ) {
    var token = localStorage.getItem('auth_token');
    if (token == undefined || token === '') {
      this.router.navigate(['/login']);
    }
    webGlObject.init();
  }

  ownerDataFormGroup: UntypedFormGroup;
  showTableData: boolean = false;
  tableColumns = [
    { dataKey: "email_id", displayName: "Fleet Owner ID" },
    { dataKey: "status", displayName: "Status" },
    { dataKey: "action", displayName: "action", custom: true },
  ];
  tabledata = [];
  public showLoading = false;
  submitted = false;
  status = true;
  errorMessages = errorMessages;
  current_user_keycloak_id: string = "";
  current_user_id: string = "";
  current_user_status = false;

  get fc() {

    return this.ownerDataFormGroup.controls;
  }

  ngOnInit() {
    this.createForm()
  }

  createForm() {
    this.ownerDataFormGroup = this.formBuilder.group({
      fleetId: ['', [Validators.pattern(checkPattern.EMAIL_VALIDATION)]],
    });
  }

  ValidateEmailFormat(value: any) {
    //if(isNaN(value)) {
    if (value.match(checkPattern.EMAIL_VALIDATION) && value.length>0) {
      this.status = true;

    }
    else {
      this.status = false;
    }


  }

  fetchData() {
    this.ValidateEmailFormat(this.ownerDataFormGroup.controls.fleetId.value)

    if (!this.status) {
      return;
    }


    this.showLoading = true;
    let reqObj = {
      "user_id": this.ownerDataFormGroup.value.fleetId,
      "module_name": appLevelConstants.GET_USER_STATUS_MODULE_NAME,
      "action": appLevelConstants.GET_USER_STATUS_ACTION
    }
    this.blockedIdManagementService.getUserStatus(reqObj).subscribe((response: any) => {
      this.showLoading = false;

      if (response.status === 1) {
        this.showTableData = false;
        this.toaster.error(response.message, '', {
          timeOut: 5000
        });
      }

      else if (response.status === 0) {
        if (response.hasOwnProperty('result')) {
          this.current_user_id = response.result.email_id;
          this.current_user_keycloak_id = response.result.keycloak_id;
          this.current_user_status = response.result.is_disabled;



          let status_message = "";
          let action_message = ""
          if (this.current_user_status) {
            status_message = "Blocked";
            action_message = "Unblock";
          }
          else {

            status_message = "Unblocked";
            action_message = "-"
          }



          this.tabledata = [
            {
              email_id: this.current_user_id,
              status: status_message,
              action: action_message
            },
          ]

          this.showTableData = true
        }
      }

      else {
        this.showTableData = false;
        this.toaster.error("Unknown Error Occured", '', {
          timeOut: 5000
        });
      }

    }, (error) => {
      this.showLoading = false;
      this.toaster.error(error.message, '', {
        timeOut: 5000
      });
    })


  }

  changeStatus() {
    if (this.ownerDataFormGroup.invalid) {
      return;
    }

    if (!this.current_user_status) {
      return;
    }


    this.showLoading = true;
    let reqObj = {
      "keycloak_id": this.current_user_keycloak_id,
      "user_id":this.current_user_id,
      "action": appLevelConstants.UNBLOCK_USER_ACTION,
      "module_name": appLevelConstants.UNBLOCK_USER_MODULE_NAME

    }
    this.blockedIdManagementService.setUserStatusToUnblock(reqObj).subscribe((response: any) => {
      this.showLoading = false;

      if (response.status === 1) {
        this.showTableData = false;
        this.toaster.error(response.message, '', {
          timeOut: 5000
        });
      }

      else if (response.status === 0) {

        this.current_user_status = false;

        this.tabledata = [
          {
            email_id: this.current_user_id,
            status: "Unblocked",
            action: "-"
          },
        ]

        this.showTableData = true

      }

      else {
        this.showTableData = false;
        this.toaster.error("Unknown Error Occured", '', {
          timeOut: 5000
        });
      }

    }, (error) => {
      this.showLoading = false;
      this.toaster.error(error.message, '', {
        timeOut: 5000
      });
    })


  }

}
