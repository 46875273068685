import { Component, OnInit } from '@angular/core';
import { UserProfileService } from 'src/app/services/user-profile.service';
import { appLevelConstants } from 'src/app/shared/configurations/app-level-constants';
import { ConfigService } from 'src/app/config.service';
import { Router } from '@angular/router';
import { USER_PLACEHODER } from '@shared/configurations/image-constants';
@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {
  USER_PLACEHODER = USER_PLACEHODER;
  title = appLevelConstants.USER_PROFILE_TITLE;
  isUserRoleAdmin: boolean = false;
  adminCount: any;
  tmlInternalCount: any;
  supportDeskCount: any;
  userInfo = this.configService.getLoggedInUserInfo();
  

  loginId = localStorage.getItem('user_id');
  contactDetailsTitle = appLevelConstants.CONTACT_DETAILS_TITLE;
  mobileNoLabel = appLevelConstants.MOBILE_NO_LABEL;
  emailIdLabel = appLevelConstants.EMAIL_LABEL;
  emailId = localStorage.getItem('user_id');
  activeUsersTitle = appLevelConstants.ACTIVE_USERS_TITLE;
  inActiveUsersTitle = appLevelConstants.INACTIVE_USERS_TITLE;
  adminLabel = appLevelConstants.ADMIN_LABEL;
  tmlInternalLabel = appLevelConstants.TML_INTERNAL_LABEL;
  supportDeskLabel = appLevelConstants.SUPPORT_DESK_LABEL;
  tmlFleetEdgeAdminLabel = appLevelConstants.TML_FLEET_EDGE_ADMIN_LABEL;
  tmlDealerAdminLabel = appLevelConstants.TML_DEALER_ADMIN_LABEL;
  reqObj: any;
  activeAdminCount: any;
  activeTmlInternalCount: any;
  activeSupportDeskCount: any;
  activeTmlFleetEdgeAdminCount: any;
  activeTmlDealerAdminCount: any;
  inActiveAdminCount: any;
  inActiveTmlInternalCount: any;
  inActiveSupportDeskCount: any;
  inActiveTmlFleetEdgeAdminCount: any;
  inActiveTmlDealerAdminCount: any;

  constructor(private userProfileService: UserProfileService, private configService: ConfigService, private router : Router) { }

  ngOnInit() {
    if (this.userInfo.role_type === 'Admin') {
      this.isUserRoleAdmin = true;
      this.getActiveUserCount();
    }
  }

  gotoAcessControl(){
    //this.router.navigate(['user-access-control']);
  }

  // FUNCTION: Function to get total user count
  // AUTHOR_NAME: YATIN RAI
  // WRITTEN ON: 16-03-2021
  // MODIFIED BY:
  getActiveUserCount() {
    
    this.reqObj = {
      user_id: localStorage.getItem('user_id'),
      role_type: localStorage.getItem('role_type'),
      action: appLevelConstants.GET_USER_COUNT_ACTION,
      module_name: appLevelConstants.MY_PROFILE_MODULE_NAME 
    };
    this.userProfileService.activeUserCount(this.reqObj).subscribe(
      (response: any) => {
        if (response.status === 0) {
         
      const status=response.result
          this.activeAdminCount = status.active_users.Admin;
          this.activeTmlInternalCount = status.active_users['TML Internal'];
          this.activeSupportDeskCount = status.active_users['Support Desk'];
          this.activeTmlFleetEdgeAdminCount =status.active_users['TML Fleet Edge Admin']
          this.activeTmlDealerAdminCount =status.active_users['TML Dealer Admin']
          this.inActiveAdminCount = status.inactive_users.Admin;
          this.inActiveTmlInternalCount = status.inactive_users['TML Internal'];
          this.inActiveSupportDeskCount = status.inactive_users['Support Desk'];
          this.inActiveTmlFleetEdgeAdminCount = status.inactive_users['TML Fleet Edge Admin']
          this.inActiveTmlDealerAdminCount = status.inactive_users['TML Dealer Admin']

        }
      },
      (error) => {
        console.log('error', error);
      }
    );
  }

}
