import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { appLevelConstants } from 'src/app/shared/configurations/app-level-constants';
import { ConfigService } from 'src/app/config.service';
import { ToastrService } from 'ngx-toastr';
import * as XLSX from 'xlsx';
import { errorMessages } from 'src/app/shared/configurations/error-messages-constants';
import { PushNotificationService } from 'src/app/services/push-notification.service';
import { Router } from '@angular/router';
import { redirectUrlConstants } from 'src/app/shared/configurations/url-constants';
import { EXCEL_SVG,GROUP_6 } from '@shared/configurations/image-constants';
@Component({
  selector: 'app-push-notification',
  templateUrl: './push-notification.component.html',
  styleUrls: ['./push-notification.component.scss']
})
export class PushNotificationComponent implements OnInit {
  GROUP_6 = GROUP_6;
  EXCEL_SVG = EXCEL_SVG;
  pushNotificationForm: UntypedFormGroup;
  title = appLevelConstants.PUSH_NOTIFICATION_TITLE;
  pushNotificationDetailLabel = appLevelConstants.PUSH_NOTFICATION_DETAILS_LABEL;
  noticationAsPer = appLevelConstants.NOTIFICATION_AS_PER;
  notificationUserType = appLevelConstants.NOTIFICATION_USER_TYPE;
  notificationSelectiveUser = appLevelConstants.NOTIFICATION_SELECTIVE_USER;
  userTypeLabel = appLevelConstants.USER_TYPE_LABEL;
  userTypes = appLevelConstants.USER_TYPE;
  notificationTitleLabel = appLevelConstants.NOTIFICATION_TITLE_LABEL;
  notificationBodyLabel = appLevelConstants.NOTIFICATION_BODY_LABEL;
  notificationTypeLabel = appLevelConstants.SELECT_NOTIFICATION_TYPE_LABEL;
  notificationTypes = appLevelConstants.SELECT_NOTIFICATION_TYPES;
  cancelButton = appLevelConstants.CANCEL_BUTTON;
  submitButton = appLevelConstants.SUBMIT_BUTTON;
  errormessages = errorMessages;
  s3Link = appLevelConstants.S3_link_bulk_upload;
  notificationBodyNote = appLevelConstants.NOTIFICATION_BODY_NOTE;

  isNotificationType: any;
  public uploadDisplay = false;
  isCorrectSheet = false;
  isFailedSheet = false;
  fileInput: any = false;
  uploadFile: UntypedFormGroup;
  templateLink: any;
  isDisabled: boolean;
  failedData: any;
  fileUploaded: File;
  fileSize: any;
  HTML_DATA_VARIABLES = appLevelConstants.HTML_VARIABLES_BULK_UPLOAD;
  submitted: boolean;
  submittedexcel: boolean;
  jsonData: unknown[];
  worksheet: XLSX.WorkSheet;
  changedObj: any;
  jsonKeyData: { data: any; };
  filename: any;
  storeData: any;
  finalNotificationModes: any = [];
  excelData: any;
  showbulkicon = false;
  selectNotificationTypeArrayEmpty = true;
  userInfo: any;
  showLoading = false;
  usertypeallarray = ['Fleet Owner', 'Fleet Manager', 'Account Manager'];



  constructor(
    private formBuilder: UntypedFormBuilder,
    private configService: ConfigService,
    private pushNotificationService: PushNotificationService,
    private toaster: ToastrService,
    private router: Router
  ) { }

  ngOnInit() {
    this.createForm();
    this.createBulkForm();
    this.isNotificationType = true;
    this.showbulkicon = true;
    this.userInfo = this.configService.getLoggedInUserInfo();
  }

  // push notification form controls
  get getFormControls() {
    return this.pushNotificationForm.controls;
  }

  // push notification form values
  get fv() {
    return this.pushNotificationForm.value;
  }

  // bulk upload form controls
  get f() { return this.uploadFile.controls; }

  // push notification create form
  createForm() {
    this.pushNotificationForm = this.formBuilder.group(
      {
        isNotificationType: [''],
        usertype: ['', [Validators.required]],
        titleName: ['', [Validators.required, Validators.minLength(3)]],
        bodyName: ['', [Validators.required, Validators.minLength(3)]],
        selectNotificationType: ['', [Validators.required]],
      });
  }

  // create bulk upload form
  createBulkForm() {
    this.uploadFile = this.formBuilder.group({
      fileInput: ['', [Validators.required]],
    });
  }

  //  onchange methood for notiifcation type
  onNotificatioTypeChange() {
    if (this.isNotificationType) {
      this.showbulkicon = true;
    } else {
      this.showbulkicon = false;
    }
  }

  //  onchange method for check box of notification mode
  onCheckboxChange(e) {
    if (e.target.checked) {
      if (this.finalNotificationModes.indexOf(e.target.value) === -1) {
        this.finalNotificationModes.push(e.target.value);
      }
    } else {
      let i: number = 0;
      this.finalNotificationModes.forEach(item => {
        if (item === e.target.value) {
          this.finalNotificationModes.splice(i, 1);
          return;
        }
        i++;
      });
    }
    if (this.finalNotificationModes.length > 0) {
      this.selectNotificationTypeArrayEmpty = false;
    } else {
      this.selectNotificationTypeArrayEmpty = true;
    }
  }

  // submit method
  onSubmit() {
    if (!this.fv.isNotificationType) {
      this.pushNotificationForm.controls.usertype.setErrors(null);
    }
    this.submitted = true;
    if (this.pushNotificationForm.invalid) {
      return;
    } else {
      this.showLoading = true;
      let returobj;
      if (this.fv.isNotificationType) {
        returobj = {
          subject: this.fv.titleName,
          message: this.fv.bodyName,
          notification_type: this.finalNotificationModes,
          user_type: this.fv.usertype.userType === 'All' ? this.usertypeallarray : [this.fv.usertype.userType],
          user_name: this.userInfo.user_id
        };
      } else {
        returobj = {
          subject: this.fv.titleName,
          message: this.fv.bodyName,
          notification_type: this.finalNotificationModes,
          user_list: this.excelData,
          user_name: this.userInfo.user_id
        };
      }
      this.pushNotificationService.userPushNotification(returobj).subscribe((response) => {
        this.showLoading = false;
        if (response.status == 0) {
          this.toaster.success(response.message, '', {
            timeOut: 5000
          });
          this.pushNotificationForm.reset();
          this.pushNotificationForm.get('isNotificationType').setValue(true);
          this.finalNotificationModes.splice(0, this.finalNotificationModes.length);
          this.selectNotificationTypeArrayEmpty = true;
          this.submitted = false;
        } else {
          this.toaster.error(response.message, '', {
            timeOut: 5000
          });
        }
      });
    }
  }


  // onclick of bulk upload icon method
  bulkUpload() {
    this.uploadDisplay = true;
    this.isFailedSheet = false;
    this.fileInput = false;
    this.isCorrectSheet = false;
    this.isDisabled = true;
    this.uploadFile.get('fileInput').reset();
    this.templateLink = this.s3Link + 'push-notificaition.xlsm';

  }

  // to upload file method
  uploadedFile(event) {
    this.filename = event.target.files[0].name;
    this.fileSize = this.configService.getBytes(event.target.files[0].size);
    this.fileUploaded = event.target.files[0];
    if (this.fileUploaded) {
      this.fileInput = true;
      this.isDisabled = false;
    }
    this.readExcel();
  }

  // method to read the uploaded excel
  readExcel() {
    const readFile = new FileReader();
    readFile.onload = (e) => {
      this.storeData = readFile.result;
      const data = new Uint8Array(this.storeData);
      const arr = new Array();
      for (let i = 0; i !== data.length; ++i) {
        arr[i] = String.fromCharCode(data[i]);
      }
      const bstr = arr.join('');
      const workbook = XLSX.read(bstr, { type: 'binary' });
      const firstSheetName = workbook.SheetNames[0];
      this.worksheet = workbook.Sheets[firstSheetName];
    };
    readFile.readAsArrayBuffer(this.fileUploaded);
  }

  // to clear the bulk uplaod form
  clearForm() {
    this.uploadFile.get('fileInput').reset('');
    this.isCorrectSheet = false;
    this.fileInput = false;
  }

  //  read json
  ReadAsJson() {
    this.submittedexcel = true;
    if (this.uploadFile.invalid) {
      this.uploadDisplay = true;
      return;
    } else {
      this.jsonData = XLSX.utils.sheet_to_json(this.worksheet, { raw: false });
      this.changedObj = this.keysToLowerCase(this.jsonData);
    }
    if (this.changedObj.length > 0) {
      this.isCorrectSheet = false;
      this.jsonKeyData = {
        data: this.changedObj,
      };
      this.returnJsonObject(this.jsonKeyData);
    } else {
      this.isCorrectSheet = true;
    }
  }

  //  to make the excel keys to lower case
  keysToLowerCase(obj) {
    obj = obj.map((item) => {
      //  tslint:disable-next-line: forin
      for (const key in item) {
        let upper = key.toLowerCase();
        //  replace all blank to '_'
        while (upper.indexOf(' ') > -1) {
          upper = upper.replace(' ', '_');
        }
        //  check if it already wasn't uppercase
        if (upper !== key) {
          item[upper] = item[key];
          delete item[key];
        }
      }
      return item;
    });
    return obj;
  }

  //  to store the excel data into an array
  returnJsonObject(event) {
    const newEvent = this.returnEvent(event);
    this.excelData = newEvent;
    this.uploadDisplay = false;
    this.showbulkicon = true;
    this.clearForm();
  }

  // to return data after modified some records to above oject
  returnEvent(event) {
    if (event.hasOwnProperty('data')) {

      // event = {
      //   data: event.data
      // };
      const newEvent = event.data;
      return newEvent;
    }
  }

  //  onclick of cancel method in bulk upload form
  cancelBulkUploadForm() {
    this.uploadDisplay = false;
    this.clearForm();
  }

  navigateToList() {
    this.router.navigateByUrl(redirectUrlConstants.REDIRECT_USER_ONBOARD);
  }
  clickCancel() {
    this.navigateToList();
  }
}
