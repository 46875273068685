import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
// import { PlanComponent } from './Components/plan/plan.component';




import { LoginComponent } from './Components/login/login.component';






import { VehicleListComponent } from './Components/client-data/vehicle-list/vehicle-list.component';
import { ClientListComponent } from './Components/client-list/client-list.component';
import { ClientApiComponent } from './Components/client-api/client-api.component';
import { UserProfileComponent } from './Components/user-profile/user-profile.component';
import { AuthGuard } from './services/auth.guard';
import { appLevelConstants } from '@shared/configurations/app-level-constants';
import { RoleCheckGuard } from './services/role-check.guard';
import { CanDeactivateGuard } from './services/can-deactivate-guard.service';

// import { UserDetailsComponent } from './Components/user-details/user-details.component';
import { AdminGuard } from './services/admin.guard';
import { AdminLogComponent } from './Components/admin-log/admin-log.component';
import { VehicleMasterCrnComponent} from './Components/vehicle-master-crn/vehicle-master-crn.component';
import { HandleShortLinksComponent } from './Components/handle-short-links/handle-short-links.component';
import { AdminRequestComponent } from './Components/admin-request/admin-request.component';
import { AdminAccessComponent } from './Components/admin-access/admin-access.component';
import { PushNotificationComponent } from './Components/push-notification/push-notification.component';
import { BlockedIdManagementComponent } from './Components/blocked-id-management/blocked-id-management.component';
import { VehicleOnBoardingComponent } from './Components/vehicle-on-boarding/vehicle-on-boarding/vehicle-on-boarding.component';
import { FuelTrendComponent } from './fuel-trend/fuel-trend.component';
const routes: Routes = [

    // NO PERMISSION ROUTES =============================
    {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full'
    },
    {
        path: 'login',
        component: LoginComponent
    },
    {
        path: 'ref_lnk',
        component: HandleShortLinksComponent,
    },


    // REROUTE===========================================
    { path: 'onboardUser',
    redirectTo: "user/onboardUser" },
    { path: 'eventdata',
    redirectTo: "events/eventdata" },
    { path: 'fuel-alerts',
    redirectTo: "events/fuel-alerts" },
    { path: 'vehicle',
    redirectTo: 'clientData/vehicle' },
    { path: 'vehiclecandata',
    redirectTo: 'vehicleData/vehiclecandata' },
    { path: 'vehiclefuel',
    redirectTo: 'vehicleData/vehiclefuel' },
    { path: 'ignition',
    redirectTo: 'vehicleData/ignition' },
    { path: 'telemetrydata',
    redirectTo: "vehicleData/telemetrydata" },
    { path: 'userActivity',
    redirectTo: "user/userActivity" },
    { path: 'user-access-control',
    redirectTo: "admin/useraccesscontrol" },
    // {
    //     path: 'bs4data',
    //     redirectTo: 'vehicleData/bs4data',
    //     canActivate: [AuthGuard]
    // },
    {
        path: 's3data',
        redirectTo: 'vehicleData/s3data',
        // canActivate: [AuthGuard]
    },
      {
        path: 'eol',
        redirectTo: 'vehicleData/eol',
        // canActivate: [AuthGuard]
    },

    // COMPONENTS===========================================
    {
        path: 'client',
        component: ClientListComponent,
        canActivate: [AuthGuard],
        data: { permission: ["Admin4"] }
    },
    {
        path: 'clientapi',
        component: ClientApiComponent,
        canActivate: [AuthGuard],
        data: { permission: ["Admin5"] }
    },
    {
        path: 'userProfile',
        component: UserProfileComponent,
        canActivate: [AuthGuard],
        data: { permission: ["Admin11"] }
    },
    {
        path: "admin-log",
        component: AdminLogComponent,
        canActivate: [AuthGuard, RoleCheckGuard],
        data: {
            permission: [
                appLevelConstants.ROLES[0],
                appLevelConstants.ROLES[1],
            ]
        }
    },
    {
        path: "vehicle-master-crm",
        component: VehicleMasterCrnComponent,
        canActivate: [AuthGuard, RoleCheckGuard],
        data: {
            permission: [
                appLevelConstants.ROLES[0],
                appLevelConstants.ROLES[1],
                appLevelConstants.ROLES[2],
            ]
        }
    },
    {
        path: "fuel-trend",
        component: FuelTrendComponent,
        canActivate: [AuthGuard, RoleCheckGuard],
        data: {
            permission: [
                appLevelConstants.ROLES[0],
                appLevelConstants.ROLES[1],
                appLevelConstants.ROLES[2],
            ]
        }
    },
    {
        path: "vehicle-on-board",
        component: VehicleOnBoardingComponent,
        canActivate: [AuthGuard, RoleCheckGuard],
        data: {
            permission: [
                appLevelConstants.ROLES[0],
                appLevelConstants.ROLES[1],
                appLevelConstants.ROLES[2],
            ]
        }
    },
    {
        path: "admin-request",
        component: AdminRequestComponent,
        canActivate: [AuthGuard, RoleCheckGuard],
        data: {
            permission: [
                appLevelConstants.ROLES[0],
                appLevelConstants.ROLES[1],
            ]
        }
    },
    {
        path: "admin-access",
        component: AdminAccessComponent,
        canActivate: [AuthGuard, RoleCheckGuard],
        data: {
            permission: [
                appLevelConstants.ROLES[0],
                appLevelConstants.ROLES[1],
            ]
        }
    },
    {
        path: "push-notification",
        component: PushNotificationComponent,
        canActivate: [AuthGuard, RoleCheckGuard],
        data: {
            permission: [
                appLevelConstants.ROLES[0],
                appLevelConstants.ROLES[1],
            ]
        }
    },
    {
        path: "blocked-id-management",
        component: BlockedIdManagementComponent,
        canActivate: [AuthGuard, RoleCheckGuard],
        data: {
            permission: [
                appLevelConstants.ROLES[0],
                appLevelConstants.ROLES[1],
                appLevelConstants.ROLES[2],
            ]
        }
    },

    // COLLECTION OF COMPONENT IMPORT AS CHILD=================================================
    {
        path: "admin",
        loadChildren: () => import("./Components/admin/admin.module").then(m => m.AdminModule),
        canActivate: [AuthGuard]
    },
    {
        path: "user",
        loadChildren: () => import("./Components/admin/admin.module").then(m => m.AdminModule),
        canActivate: [AuthGuard]
    },
    {
        path: "user",
        loadChildren: () => import("./Components/user/user.module").then(m => m.UserModule),
        canActivate: [AuthGuard]
    },
    {
        path: "events",
        loadChildren: () => import("./Components/events/events.module").then(m => m.EventsModule),
        canActivate: [AuthGuard]
    },
    {
        path: "vehicleData",
        loadChildren: () => import("./Components/vehicle-data/vehicleData.module").then(m => m.VehicleDataModule),
        canActivate: [AuthGuard]
    },
    {
        path: "clientData",
        loadChildren: () => import("./Components/client-data/clientData.module").then(m => m.ClientDataModule),
        canActivate: [AuthGuard]
    },
    {
        path: "subscription",
        loadChildren: () => import("./Components/post-subscription/post-subscription.module").then(m => m.PostSubscriptionModule),
        canActivate: [AuthGuard]
    },

];

@NgModule({
    imports: [RouterModule.forRoot(routes, { useHash: true })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
