import { Injectable, EventEmitter } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class ConfigService {
  firebaseConfig: any;
  getConfig() {
    throw new Error('Method not implemented.');
  }
  public moduleName: string;

  username: any;
  userInfoChangeEvent: EventEmitter<any> = new EventEmitter();
  isAllVerified = false;
  branchID = new BehaviorSubject('');
  branchChange = new BehaviorSubject('');
  moduleNameChange = new BehaviorSubject('');
  loggedInUserInfo: any;

  constructor() { }

  getKeyCloakToken() {
    return 'Bearer ' + localStorage.getItem('auth_token');
  }

  getApplicationHeaders() {
    return {
      headers: new HttpHeaders({
        'Content-type': 'application/json',
      })
    };
  }

  getAddEditHeaders() {
    return {
      headers: new HttpHeaders({
        'Content-type': 'application/json',
        Authorization: this.getKeyCloakToken()
      })
    };
  }

  setLoggedInUserInfo(userInfo) {
    localStorage.setItem('loggedInUserInfo', JSON.stringify(userInfo));
  }

  getLoggedInUserInfo() {
    return this.loggedInUserInfo = JSON.parse(
      localStorage.getItem('loggedInUserInfo')
    );
  }

  getBytes(bytes) {
    const decimals = 2;
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }
}
