import { Injectable } from '@angular/core';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { ConfigService } from '../config.service';
import { environmentKeys } from 'environment-keys'

const firebaseConfig = environmentKeys.qa.firebaseConfig;

const app = initializeApp(firebaseConfig);

const analytics = getAnalytics(app);

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {

  constructor(private configService: ConfigService) { }

  userInfo: any;

  createEvent(eventName: string, eventParams: any) {  
    logEvent(analytics, eventName, eventParams);
  }
}



