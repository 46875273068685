import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { ConfigService } from 'src/app/config.service';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})

export class AuthGuard  {
  public isAuthenticated;
  showHead: boolean = false;
  showBackGround: boolean = false;
  bgClasname = "lyt-main";

  constructor(public configService: ConfigService, public toaster: ToastrService) { }

  // Call check authenticated user api request when user is active
  canActivate(route: ActivatedRouteSnapshot): boolean {
    const userDetails = this.configService.getLoggedInUserInfo();
    let grant = !!localStorage.getItem("auth_token") && !!userDetails;
    if (!grant) {
      localStorage.clear();
      window.location.href = '/login';
      this.showHead = false;
		  this.showBackGround=false;
		  this.bgClasname = "lyt-main";
      return false;
    }

    return grant;
  }
}
