// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.invalid-error {
  margin-top: 4rem !important;
}

.action-button {
  cursor: pointer;
  font-weight: 700;
  color: blue;
}

.boxColor {
  border-color: #db0b0b !important;
}`, "",{"version":3,"sources":["webpack://./src/app/Components/blocked-id-management/blocked-id-management.component.scss","webpack://./../../IPS%20frontend/cvp-infinity-internal-service-portal-frontend/src/app/Components/blocked-id-management/blocked-id-management.component.scss"],"names":[],"mappings":"AAAA;EACI,2BAAA;ACCJ;;ADEA;EACI,eAAA;EACA,gBAAA;EACA,WAAA;ACCJ;;ADEA;EACI,gCAAA;ACCJ","sourcesContent":[".invalid-error {\r\n    margin-top: 4rem !important;\r\n}\r\n\r\n.action-button {\r\n    cursor: pointer;\r\n    font-weight: 700;\r\n    color: blue;\r\n}\r\n\r\n.boxColor{\r\n    border-color: #db0b0b !important;\r\n}",".invalid-error {\n  margin-top: 4rem !important;\n}\n\n.action-button {\n  cursor: pointer;\n  font-weight: 700;\n  color: blue;\n}\n\n.boxColor {\n  border-color: #db0b0b !important;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
