import { Component, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ConfigService } from 'src/app/config.service';
import { VehicleCanDataService } from '../services/vehicle-can-data.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { checkPattern } from '@shared/configurations/pattern-constants';
import { errorMessages } from '@shared/configurations/error-messages-constants';
import * as moment from 'moment';
import { appLevelConstants } from 'src/app/shared/configurations/app-level-constants';
import {
  ChartComponent,
  ApexAxisChartSeries,
  ApexChart,
  ApexXAxis,
  ApexDataLabels,
  ApexStroke,
  ApexYAxis,
  ApexTitleSubtitle,
  ApexLegend,
  ApexTooltip,
  ApexFill
} from "ng-apexcharts";

export type chartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  stroke: ApexStroke;
  dataLabels: ApexDataLabels;
  yaxis: any;
  title: ApexTitleSubtitle;
  labels: string[];
  legend: ApexLegend;
  tooltip: ApexTooltip;
  fill: ApexFill;
  colors: any;
  responsive: any;
};

@Component({
  selector: 'app-fuel-trend',
  templateUrl: './fuel-trend.component.html',
  styleUrls: ['./fuel-trend.component.scss']
})
export class FuelTrendComponent implements OnInit {
  @ViewChild("chart", { static: true }) chart: ChartComponent;
  public fuelChartOptions: Partial<chartOptions>;
  fuelArray: any;
  checkChart: boolean;
  displayChartMessage: any;
  vehicleData: any
  userInfo = this.configService.getLoggedInUserInfo();
  vehicleFuelTrend: UntypedFormGroup;
  checkPattern = checkPattern;
  errorMessages = errorMessages;
  submitted = false;
  is_scv_ev: boolean;
  is_scv: boolean;

  req = {
    "vehicleIds": [],
    "fields": [],
    "from": "",
    "to": "",
    "is_data_processing": true,
    "is_scv_ev_vehicle": false,
    "is_scv_vehicle": false,
    "auser_name": localStorage.getItem('user_id')
  }

  locale = {
    format: 'DD/MM/YYYY HH:mm:ss',
    firstDay: 1
  };
  public todayDate = moment().format('YYYY-MM-DDTHH:mm:ss.SSS') + '000';
  showGraphData: boolean = false;
  vinLabel = appLevelConstants.VIN_NUMBER;
  dateLabel = appLevelConstants.DATE_TIMESTAMP_LABEL;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private toaster: ToastrService,
    public spinnerService: NgxSpinnerService,
    private vehicleCanDataService: VehicleCanDataService,
    public configService: ConfigService,

  ) { }

  ngOnInit() {
    this.vehicleFuelTrend = this.formBuilder.group({
      vinNumber: ['', [Validators.required, Validators.pattern(this.checkPattern.VIN_VALID_NO)]],
      dateTime: ['', [Validators.required]],
    });
    this.vehicleFuelTrend.controls.dateTime
      .valueChanges.subscribe(
        (value) => {
          if (value) {
            if (value.startDate && value.endDate) {
              const start = value.startDate.toDate();
              const end = value.endDate.toDate();
              if (start > end) {
                this.fc.dateTime.setErrors({ invalid: true });
              }
              const hoursDifference = Math.abs(end - start) / 36e5;
              if (hoursDifference > 360) {
                this.fc.dateTime.setErrors({ incorrect: true });
              }
            } else {
              this.fc.dateTime.setErrors({ required: true });
            }
          }
        }
      );

    this.vehicleFuelTrend.controls.vinNumber.valueChanges.subscribe((value) => {
      if (value) {
        if (value.match(this.checkPattern.VIN_VALID_NO)) {
          this.vehicleData = {
            vin: value
          }
        } else {
          this.fc.vinNumber.setErrors({ pattern: true })
        }
      }
    })
  }


  getFuelArray = (vehicleData) => {
    this.checkChart = false;
    this.displayChartMessage = 'Loading..';
    let req = {
      "vehicleIds": [this.vehicleFuelTrend.value.vinNumber],
      "fields": ["noOfFuelTanks", "primaryFuelLevel", "secondaryFuelLevel1"],
      "from": moment(this.fc.dateTime.value.startDate.toDate()).format("YYYY-MM-DDTHH:mm:ss"),
      "to": moment(this.fc.dateTime.value.endDate.toDate()).format("YYYY-MM-DDTHH:mm:ss"),
      "is_data_processing": true,
      "is_scv_ev_vehicle": false,
      "is_scv_vehicle": false,
      "auser_name": localStorage.getItem('user_id')
    }
    this.vehicleCanDataService.getVehicleFuelTrend(req).subscribe(
      (response: any) => {
        if (response.hasOwnProperty('resp_array') || response.hasOwnProperty('fuel_array')) {
          this.fuelArray = { fuel_array: response.resp_array ? response.resp_array : response.fuel_array };
          const minimumVal = this.getMinimumMaximumValueForChart(this.fuelArray).minimumVal;
          const maximumVal = this.getMinimumMaximumValueForChart(this.fuelArray).maximumVal;
          const fuelChartResponse = {
            ...this.fuelArray,
          };
          if (this.fuelArray) {
            this.checkChart = true;
            this.displayChartMessage = this.toaster.success(response.message)
            this.plotFuelChart(fuelChartResponse, minimumVal, maximumVal);
          } else {
            this.checkChart = false;
            this.displayChartMessage = response.message;
          }
        } else {
          this.checkChart = false;
          this.displayChartMessage = response.message;
        }

      },
      (error) => {
        console.log('error', error);
        if (this.fuelArray) {
          this.checkChart = true;
          this.displayChartMessage = '';
        } else {
          this.checkChart = false;
          this.displayChartMessage = 'No Data Found';
        }
      }
    );
  };

  getMinimumMaximumValueForChart = (fuelArray) => {
    let allFuelArray = [];
    fuelArray.fuel_array.forEach(ele=>{
      allFuelArray.push(ele[1])
    })
    let maximumVal = Math.max.apply(null,allFuelArray);
    let minimumVal = Math.min.apply(null,allFuelArray);
    // Set the min and max value for y axis fuel trend chart
    if (minimumVal === maximumVal) {
      minimumVal = 0;
      maximumVal = maximumVal + 10;
    } else {
      minimumVal = (minimumVal > 0) ? minimumVal - 5 : minimumVal;
      maximumVal = maximumVal + 5;
    }
    return {
      maximumVal,
      minimumVal
    };
  }

  plotFuelChart = (response, minimumVal, maximumVal) => {
    this.fuelChartOptions = {
      series: [
        {
          name: "Fuel",
          data: response.fuel_array,
        },
      ],
      chart: {
        type: "area",
        height: 350,
        animations: {
          enabled: false
        },
        toolbar: {
          show: true,
          tools: {
            download: false,
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      colors: ["#607AA3"], // #ffffff00 hexcode for transparent white color
      stroke: {
        curve: "stepline", // curve: ['smooth', 'straight', 'stepline']
        width: 2,
      },
      title: {
        text: "Fuel Trends",
        align: "center",
      },
      legend: {
        show: false,
      },
      xaxis: {
        type: "datetime",
        labels: {
          format: "dd MMM, H:mm:ss",
          datetimeUTC: false,
        },
        tooltip: {
          enabled: false,
        },
      },
      yaxis: [
        {
          //For Fuel yaxis formatter
          labels: {
            formatter: function (value) {
              return Math.round(value);
            },
          },
          min: minimumVal,
          max: maximumVal,
          title: {
            text: "Fuel(Litre)",
          },
        },
      ],
      tooltip: {
        x: {
          format: "ddd dd MMM, H:mm:ss",
        },
        marker: {
          show: false,
        },
      },
      fill: {
        type: "gradient",
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.7,
          opacityTo: 0.9,
          stops: [0, 100],
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: "100%",
              toolbar: {
                offsetY: 15,
              },
            },
            title: {
              align: "left",
            },
          },
        },
        {
          breakpoint: 768,
          options: {
            chart: {
              width: "100%",
              toolbar: {
                offsetY: 15,
              },
            },
            title: {
              align: "left",
            },
          },
        },
        {
          breakpoint: 1024,
          options: {
            chart: {
              width: "100%",
              toolbar: {
                offsetY: 15,
              },
            },
            title: {
              align: "left",
            },
          },
        },
        {
          breakpoint: 1440,
          options: {
            chart: {
              width: '100%',
            }
          }
        },
        {
          breakpoint: 2560,
          options: {
            chart: {
              width: "100%",
            },
          },
        },
      ],
    };
  };

  get fc() {
    return this.vehicleFuelTrend.controls;
  }


  fetchData(arg) {
    this.submitted = true;
    if (this.vehicleFuelTrend.invalid) {
      return;
    }
    this.showGraphData = true;
    this.getFuelArray(this.vehicleData)
  }

}