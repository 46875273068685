import { Component } from '@angular/core';
// import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { LoginService } from './login.service';
import { Router, NavigationEnd, NavigationStart, NavigationCancel, NavigationError, RouterEvent } from '@angular/router';
import { Cookie } from 'ng2-cookies/ng2-cookies';
import { NgxSpinnerService } from 'ngx-spinner';
import { LoaderService } from './services/loader.service';
import { ChangeDetectorRef } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import * as moment from 'moment';



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  public toaster: ToastrService;
  title = 'tata-motors';
  showHead: boolean = false;
  showBackGround: boolean = true;
  bgClasname = "lyt-main";
  // public config: SwiperConfigInterface = {
  //   slidesPerView: 3,
  //   spaceBetween: 20
  // }
  public show: boolean = false;
  public showLoading = false;
  userPublicIp = null

  showWaterMark = false
  waterMarkDateTime = null
  waterMarkUserId = null

  routerLoading: boolean = false;
  vehCurr = 25000;
  vehTot = 45000;
  calVehHealth() {
    return {
      'transform': 'translateX(-100%) rotate(' + (this.vehCurr / this.vehTot) * 180 + 'deg)'
    }
  }
  constructor(private router: Router, public loginService: LoginService, private loader: LoaderService,
    private http:HttpClient,
    private cdr: ChangeDetectorRef) {
    router.events.forEach((event) => {
      this.getWatermark();
      if (event instanceof NavigationStart) {
        if (event['url'] == '/login' || event['url'] == '/') {
          this.showHead = false;
          this.showBackGround = true;
          this.bgClasname = "lyt-main";
        } else {
          let grant = !!localStorage.getItem("auth_token")
          var today = new Date();
          if (!grant) {
            localStorage.refresh_time = today;
            this.showHead = false;
            this.showBackGround = false;
            this.bgClasname = "lyt-main";
          }
          else {
            localStorage.refresh_time = today;
            this.showHead = true;
            this.showBackGround = false;
            this.bgClasname = "";
          }
        }
      }
    });
  }


  ngOnInit() {
    console.log("TML internal deployed on QA")
    this.http.get("https://api.ipify.org/?format=json").subscribe((res:any)=>{
      if(res && res.ip){
        this.userPublicIp = res.ip
      }
    });
    this.router.events.subscribe((e : any) => {
      this.navigationInterceptor(e);
    })

  }

  getWatermark(){
    let userData = JSON.parse(localStorage.getItem('loggedInUserInfo'))
    let date = moment(new Date()).format('DD MMM YYYY, hh:mm a')
    if(userData){
      this.showWaterMark = true
      this.waterMarkUserId = userData.user_id
      this.waterMarkDateTime = date

    }
    else{
      this.showWaterMark = false
      this.waterMarkUserId = null
      this.waterMarkDateTime = null
    }
  }

  navigationInterceptor(e : RouterEvent){
    if (e instanceof NavigationStart) {
      this.routerLoading = true
    }
    if (e instanceof NavigationEnd) {
      
      this.routerLoading = false
  }
  if (e instanceof NavigationCancel) {
    this.routerLoading = false
  }
  if (e instanceof NavigationError) {
    this.routerLoading = false
  }
}

  ngAfterViewChecked() {
    this.loader.showLoader
      ? this.showLoading = true
      : this.showLoading = false;

    this
      .cdr
      .detectChanges();
  }
}
