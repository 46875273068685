// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.disclaimer {
  font-size: 13px;
  font-weight: 400;
  font-stretch: normal;
  font-style: italic;
  letter-spacing: normal;
  color: #666;
  margin-bottom: 1rem;
}

.loading {
  margin-top: 20px;
  margin-left: 0;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1607843137);
  display: flex;
  width: 100%;
  flex-direction: column;
  border: none;
  padding: 15px;
  background-color: #fff;
  min-height: 280px;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
}`, "",{"version":3,"sources":["webpack://./src/app/fuel-trend/fuel-trend.component.scss","webpack://./../../IPS%20frontend/cvp-infinity-internal-service-portal-frontend/src/app/fuel-trend/fuel-trend.component.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,gBAAA;EACA,oBAAA;EACA,kBAAA;EACA,sBAAA;EACA,WAAA;EACA,mBAAA;ACCJ;;ADEA;EACI,gBAAA;EACA,cAAA;EACA,iDAAA;EACA,aAAA;EACA,WAAA;EACA,sBAAA;EACA,YAAA;EACA,aAAA;EACA,sBAAA;EACA,iBAAA;EACA,uBAAA;EACA,mBAAA;EACA,iBAAA;ACCJ","sourcesContent":[".disclaimer {\r\n    font-size: 13px;\r\n    font-weight: 400;\r\n    font-stretch: normal;\r\n    font-style: italic;\r\n    letter-spacing: normal;\r\n    color: #666;\r\n    margin-bottom: 1rem;\r\n}\r\n\r\n.loading {\r\n    margin-top: 20px;\r\n    margin-left: 0;\r\n    box-shadow: 0 1px 4px #00000029;\r\n    display: flex;\r\n    width: 100%;\r\n    flex-direction: column;\r\n    border: none;\r\n    padding: 15px;\r\n    background-color: #fff;\r\n    min-height: 280px;\r\n    justify-content: center;\r\n    align-items: center;\r\n    font-size: 1.5rem;\r\n}",".disclaimer {\n  font-size: 13px;\n  font-weight: 400;\n  font-stretch: normal;\n  font-style: italic;\n  letter-spacing: normal;\n  color: #666;\n  margin-bottom: 1rem;\n}\n\n.loading {\n  margin-top: 20px;\n  margin-left: 0;\n  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1607843137);\n  display: flex;\n  width: 100%;\n  flex-direction: column;\n  border: none;\n  padding: 15px;\n  background-color: #fff;\n  min-height: 280px;\n  justify-content: center;\n  align-items: center;\n  font-size: 1.5rem;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
